(function () {

  const multipleFileInputApp = (fieldId) =>  {
    return {
      bid_documents: 'Browse Files',
      getNameFile: function () {
        if (document.getElementById('bid_documents').value != '') {
          if (document.getElementById('bid_documents').files.length > 1) {
            this.bid_documents = document.getElementById('bid_documents').files.length + ' Files selected'
          } else {
            this.bid_documents = document.getElementById('bid_documents').files[0].name
          }
        } else {
          this.bid_documents = 'Browse File';
        }
      },
      autoValidateFile: function (){
        var attachments = document.getElementById('bid_documents').files
        for (let i = 0; i < attachments.length; i++) {
          var attachment = attachments[i]
          // var image_supported_types = ['image/jpg','image/gif','image/png','image/jpeg','image/bmp','image/tiff','image/svg+xml'];
          // var video_supported_types = ['video/mp4','video/ogg','video/mpeg4','video/webm'];
          var file_supported_types = ['image/jpg','image/gif','image/png','image/jpeg','image/bmp','image/tiff','image/svg+xml','application/msword','application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/pdf','text/plain'];
          attachment_type = '';
          if(file_supported_types.includes(attachment.type)){
            attachment_type = 'file';
          }else{
            document.getElementById('bid_documents').value = ''
            document.getElementById("attachment_block").__x.$data.errortext = attachment.name+" "+attachment.name.split('.').pop()+" Invalid file format";
            return;
          }
          var file_size = attachment_type == 'image' ? 50 : (attachment_type == 'video' ? 300 : 100)
          var msg_formats = attachment_type == 'image' ? ['jpg','gif','png','bmp','tiff','svg'] : (attachment_type == 'video' ? ['mp4','ogg','mpeg4','webm'] : ['txt','doc','docx','pdf','xls','xlsx'])
          if((attachment.size / (1024 * 1024)) > file_size){
            document.getElementById('bid_documents').value = ''
            document.getElementById("attachment_block").__x.$data.errortext = attachment_type+" size cannot be greater than "+ file_size +"MB";
            return;
          }
        }
        // document.getElementById("attachment_attachment_type").value = attachment_type
        document.getElementById("attachment_form").submit();
        document.getElementById("attchment_loader_block").__x.$data.loader = true;
      },
    };
  };

  if (typeof window !== "undefined") {
    window.multipleFileInputApp = multipleFileInputApp;
  }
  return multipleFileInputApp;
})();