// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
window.Rails = Rails;
Rails.start();

import { Turbo } from '@hotwired/turbo-rails';
window.Turbo = Turbo;

import LocalTime from "local-time"
LocalTime.start();

var current_time = new Date();
document.cookie = "time_zone=" + current_time.getTimezoneOffset();

require("@rails/activestorage").start()
require("channels")

require('alpine-turbo-drive-adapter')
require('alpinejs')
require('inputmask/dist/inputmask')
require('flatpickr')

require('../src/modal')
require('../src/mask')
require('../src/placeInput')
require('../src/previewFileInputApp')
require('../src/notifications')
require('../src/sendRequest')
require('../src/advancedSearch')
require('../src/projects/attachments')
require('../src/projects/attachmentButton')
require('../src/projects/fileUpload')
require('../src/multipleFileInput')
require('../src/attachmentList')
require('../src/homeCompanyGoogleMapsJsApp')
require('../src/tos')
require('../src/reviewStars')
require('../src/stripeForm')
require('../src/stripePayment')
require('../src/stripeCheckout')
require('../src/bankAccount')
require('../src/paymentSchedule/paymentScheduleExecuteFormApp')
require('../src/paymentSchedule/paymentScheduleUpdateFormApp')

import 'flatpickr/dist/flatpickr.css'
import '../stylesheets/application.css'
import '../stylesheets/material-design.scss'
import '../stylesheets/sizes.scss'

import Tagify from '@yaireo/tagify'
window.Tagify = Tagify;

require.context('../images', true);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
