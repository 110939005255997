(function () {

  const homeCompanyGoogleMapsJsApp = function (current_user, url, filter) {
    let map;
    let markers = [];
    let templateInfoWindow = '<div><div class="flex flex-col"><a class="text-brand-500" href="{{PROJECT_URL}}"><div class="flex flex-row"><img src="{{FIRST_IMG}}" class="w-50px"><div class="flex-row justify-center ml-1"><p><b>{{NAME}}</b></p><p>{{ADDRESS}}</p><p>{{ZIP}}</p></div></div></a></div></div>';
    let infowindow = new google.maps.InfoWindow({disableAutoPan: true});

    const filterSelected = filter;
    const searchURL = url;
    const user = current_user;
    const mapOptions = {
      center: { lat: parseFloat(user.latitude), lng: parseFloat(user.longitude) },
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP]
      },
      mapTypeControl: false,
      zoom:  document.getElementById('q_zoom').value ? parseInt(document.getElementById('q_zoom').value) : 9,
      streetViewControl: false,
      minZoom: 5,
      maxZoom: 15
    };

    const clearMarkers = () => {
      if (markers.length > 0) {
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
      }
    }

    const isInsideMapView = (latLng) => {
      return map.getBounds().contains(latLng);
    }

    const createMarker = (project) => {
      let marker = new google.maps.Marker({
        position: { lat: parseFloat(project.latitude), lng: parseFloat(project.longitude) },
        map: map,
        icon: { url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" }
      });
      marker.addListener('click', function() {
        infowindow.setContent(templateInfoWindow.replace('{{PROJECT_URL}}', project.path)
                                                .replace('{{FIRST_IMG}}', project.image)
                                                .replace('{{NAME}}', project.name)
                                                .replace('{{ADDRESS}}', project.address)
                                                .replace('{{ZIP}}', project.zip));
        infowindow.open(map, this);
      });
      return marker;
    }

    const manageMarkers = (projects) => {
      clearMarkers();
      markers = projects.map((project) => {
        if (isInsideMapView(new google.maps.LatLng(parseFloat(project.latitude),
                                                   parseFloat(project.longitude))) &&
            locationParam() == 0)
        {
          return createMarker(project);
        } else {
          return createMarker(project);
        }
      }).filter((marker) => { return marker; });
    }

    const formatQueryParams = queryParams => {
      return Object.keys(queryParams)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k]))
            .join('&');
    };

    const search = (url, options) => {
      let urlParams = new URLSearchParams(window.location.search).toString();
      urlParams = urlParams.concat('&', options.hasOwnProperty('params') ? formatQueryParams(options.params) : null);
      url = urlParams != null ? url + '?' + urlParams : url;
      return fetch(url, options);
    };

    const setCompanyCenter = () => {
      map.setCenter({ lat: parseFloat(user.latitude), lng: parseFloat(user.longitude)  });
      let marker = new google.maps.Marker({
        position: { lat: parseFloat(user.latitude), lng: parseFloat(user.longitude) },
        map: map,
        icon: { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" }
      });
      marker.addListener('click', function() {
        infowindow.setContent(templateInfoWindow.replace('{{PROJECT_URL}}', 'javascript')
                                                .replace('{{FIRST_IMG}}', user.image)
                                                .replace('{{NAME}}', user.name + ' (You are here)')
                                                .replace('{{ADDRESS}}', user.address)
                                                .replace('{{ZIP}}', user.zip));
        infowindow.open(map, this);
      });
    }

    const addListenerMap = (__this) => {
      map.addListener("idle", () => {
        window.setTimeout(() => {
          ne_lat = map.getBounds().getNorthEast().lat();
          ne_lng = map.getBounds().getNorthEast().lng();
          sw_lat = map.getBounds().getSouthWest().lat();
          sw_lng = map.getBounds().getSouthWest().lng();

          search(searchURL, {'params': { bounds: 1,
                                        ne_lat: ne_lat,
                                        ne_lng: ne_lng,
                                        sw_lat: sw_lat,
                                        sw_lng: sw_lng,
                                      }
          }).then(response => {
            return response.json();
          })
          .then(data => {
            __this.projects = data;
            __this.newest_projects = data['newest_projects'];
            __this.near_you = data['near_you'];
            __this.end_soon = data['end_soon'];

            manageMarkers(__this.projects[__this.filter]);
          })
          .catch(function(err) {
            return err;
          });
        }, 1000);
      });
    }

    const locationParam = () => {
      loc = document.querySelector('input[name="q[location]"]:checked');
      if (loc) {
        return parseInt(loc.value);
      }

      return loc.value = 0;
    }

    const addListenerZoom = () => {
      return google.maps.event.addListener(map, 'zoom_changed', function() {
        document.getElementById('q_zoom').value = map.getZoom();
        document.getElementsByClassName('zoom-control-js').map
        let links = document.getElementsByClassName('zoom-control-js');

        for (let link of links) {
          decoded = decodeURI(link.href);
          if (decoded.includes('zoom')) {
            decoded = decoded.replace(/zoom]=\d+/, 'zoom]='+map.getZoom())
          } else {
            decoded = decoded.concat('&q[zoom]='+map.getZoom());
          }
          link.href = encodeURI(decoded);
        }
      });
    }

    return {
      open: false,
      selected: document.getElementById('q_view').value,
      filter: filterSelected,
      mobileScreen: document.getElementById('q_mobile_screen').value,
      projects: {},
      newest_projects: [],
      near_you: [],
      end_soon: [],
      changeFilter: function(projects) {
        manageMarkers(projects);
      },
      initMap: function (id, data) {
        let __this = this;
        map = new google.maps.Map(document.getElementById(id), mapOptions);
        google.maps.event.addListenerOnce(map, 'idle', function() {
          __this.projects = data;
          __this.newest_projects = data['newest_projects'];
          __this.near_you = data['near_you'];
          __this.end_soon = data['end_soon'];

          setCompanyCenter();
          if (document.getElementById('q_mobile_screen').value != 'list') {
            addListenerMap(__this);
          }
          if (window.innerWidth > 640) {
            addListenerMap(__this);
          }
          addListenerZoom();
        });
      }
    }
  };

  if (typeof window !== "undefined") {
    window.homeCompanyGoogleMapsJsApp = homeCompanyGoogleMapsJsApp;
  }
  return homeCompanyGoogleMapsJsApp;
})();
