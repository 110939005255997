(function () {
  const paymentScheduleUpdateFormApp = function () {
    return {
      uri: document.getElementsByClassName("uri-js")[0].textContent,
      paymentSchedule: JSON.parse(document.getElementsByClassName("data-js")[0].textContent)
    };
  };

  if (typeof window !== "undefined") {
    window.paymentScheduleUpdateFormApp = paymentScheduleUpdateFormApp;
  }
  return paymentScheduleUpdateFormApp;
})();