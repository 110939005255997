(function () {
  const previewFileInputApp = function (fileInputId, defaultPreviewUrl, defaultFilename) {
    return {
      previewUrl: defaultPreviewUrl,
      filename: defaultFilename,
      updatePreview() {
        const files = document.getElementById(fileInputId).files;
        const reader = new FileReader();

        reader.onload = e => {
          this.previewUrl = e.target.result;
          this.filename = files[0].name;
        };

        reader.readAsDataURL(files[0]);
      },
    };
  };

  if (typeof window !== "undefined") {
    window.previewFileInputApp = previewFileInputApp;
  }

  return previewFileInputApp;
})();
