(function () {
  const formatQueryParams = queryParams => {
    return Object.keys(queryParams)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k]))
          .join('&');
  };

  const sendRequest = (object, url, options) => {
    let params = options.hasOwnProperty('params') ? formatQueryParams(options.params) : null;
    url = params != null ? url + '?' + params : url

    return fetch(url, options)
      .then(response => response.json())
      .then(data => {
        Rails.fire(object, 'ajax:success', [data]);

        return data;
      }).catch(function(err) {
        console.log('Failed to fetch page: ', err);

        return err;
      });
  };

  if (typeof window !== "undefined") {
    window.sendRequest = sendRequest;
  }

  return sendRequest;
})();
