(function () {
  const tosApp = function (tos) {

    return {
      tos: tos,
      updateTos: function (url) {
        let token = document.querySelector('meta[name="csrf-token"]').content;
        fetch(url, {  method: 'PATCH',
                      body: JSON.stringify({ bid: { tos: !this.tos } }),
                      headers: { 'Content-type': 'application/json; charset=UTF-8',
                                'X-CSRF-TOKEN': token }
                    })
        .then(response => response.json())
        .then(json => {
          this.tos = !this.tos;
        });
      }
    };
  };

  if (typeof window !== "undefined") {
    window.tosApp = tosApp;
  }
  return tosApp;
})();