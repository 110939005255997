(function () {

  window.redirectToStripeInPerson = function(project_id) {
    fetch('/projects/in_person_bid/stripe_checkout', {
      method: 'POST',
      body: JSON.stringify({project_id: project_id}),
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      window.location.href = data.url;
    });
  };

  window.redirectToStripeOnlineBid = function(project_id, bid_id) {
    fetch('/online_bid/stripe_checkout', {
      method: 'POST',
      body: JSON.stringify({project_id: project_id, bid_id: bid_id}),
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      window.location.href = data.url;
    });
  };


  window.redirectToStripeCheckoutEscrowPay = function(payment_schedule_id, payment_schedule_bid_id) {
      console.log('Function is being executed');
      fetch(`/bids/${payment_schedule_bid_id}/payment_schedules/${payment_schedule_id}/approve`,{
        method: 'POST',
        body: JSON.stringify({payment_schedule_id: payment_schedule_id,
                              payment_schedule_bid_id: payment_schedule_bid_id}),
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.url) {
          window.location.href = data.url;
          console.log('Redirecting to Stripe checkout...');
        } else {
          // handle error
          console.error(data.error);
        }
      });
  }

  // This fixes the error unable to post message to https://js.stripe.com.
  // Recipient has origin https://builderbreeze.com. Cause by turbolinks.
  const stripeIFrameQuery = 'iframe[src^="https://js.stripe.com"]';
  document.addEventListener('turbo:before-render', function (event) {
    const stripeIFrame = document.querySelector(stripeIFrameQuery);
    const newStripeIFrame = event.detail.newBody.querySelector(stripeIFrameQuery);
    if (stripeIFrame && !newStripeIFrame){
      event.detail.newBody.appendChild(stripeIFrame)
    }
  });

})();
