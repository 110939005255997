(function () {
  const attachmentsApp = () => {
    return {
      updateAttachment: function (id, url) {
        let token = document.querySelector('meta[name="csrf-token"]').content

        let formData = new FormData();
        formData.append('attachment[name]', document.getElementById('attachment_name_' + id).value);
        formData.append('attachment[tag_list]', document.getElementById('attachment_tag_list_' + id).value);

        const headers = new Headers({
            'X-CSRF-TOKEN': token
        });

        window.sendRequest(
          document.getElementById('attachment_name_' + id),
          url,
          {
            headers: headers,
            method: 'PATCH',
            body: formData,
          }
        );
      }
    };
  }

  if (typeof window !== "undefined") {
    window.attachmentsApp = attachmentsApp;
  }

  return attachmentsApp;
})();
