(function () {
  const maskApp = () =>  {
    return {
      phoneMask: function(field, mask) {
        let selector = document.getElementById(field);
        let inputMask = new Inputmask(mask, {
          removeMaskOnSubmit: false,
        });
        inputMask.mask(selector);
      },
      routingMask: function(field, mask) {
        let selector = document.getElementById(field);
        let inputMask = new Inputmask(mask, {
          removeMaskOnSubmit: false,
        });
        inputMask.mask(selector);
      },
      moneyMask: function(field, mask) {
        let selector = document.getElementById(field);
        let inputMask = new Inputmask({
          'alias': 'numeric',
          'groupSeparator': ',',
          'autoGroup': true,
          'digits': 2,
          'radixPoint': ".",
          'digitsOptional': false,
          'allowMinus': false,
          'prefix': '$ ',
          'placeholder': ''
        },
        {
          removeMaskOnSubmit: true,
        });
        inputMask.mask(selector);
      }
    };
  };

  if (typeof window !== "undefined") {
    window.maskApp = maskApp;
  }
  return maskApp;
})();