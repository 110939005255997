(function () {
  const style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  const stripePaymentApp = (clientSecret) =>  {
    const publishableKey = document.querySelector('meta[name=stripe-pkey]').getAttribute('content');

    const stripe = window.Stripe(publishableKey);
    const elements = stripe.elements();

    const cardElement = elements.create('card', { style, hidePostalCode: true });

    return {
      loading: false,
      error: null,
      mount: function (id) {
        const __this = this;

        cardElement.mount(`#${id}`);

        cardElement.on('change', function (event) {
          if (event.error) {
            __this.error = event.error.message;
            __this.loading = false;
          } else {
            __this.error = null;
          }
        });
      },
      pay: function (callback) {
        this.loading = true;

        stripe
          .confirmCardPayment(clientSecret, {
            payment_method: { card: cardElement },
          })
          .then(function (result) {
            this.loading = false;

            if (result.paymentIntent) callback && callback();
          })
      }
    };
  };

  if (typeof window !== "undefined") {
    window.stripePaymentApp = stripePaymentApp;
  }
  return stripePaymentApp;
})();
