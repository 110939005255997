(function () {
  const stripeFormApp = (dismiss) => {
    const publishableKey = document.querySelector('meta[name=stripe-pkey]').getAttribute('content');

    const stripe = window.Stripe(publishableKey);
    const elements = stripe.elements();
    const cardElement = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          fontSize: '16px',
          color: '#0E0955',
        },
      }
    });

    return {
      loading: false,
      clientSecret: null,
      bidProject: function (bidProjectUrl) {
        const __this = this;
        setTimeout(() => {
          fetch(
            bidProjectUrl,
            {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method: 'POST'
            }
        ).then(response => response.json())
         .then(json => window.location.href = json.bid_url);
        }, 3000);
      },
      fetchClientSecret: async function () {
        const __this = this;
        this.loading = true

        fetch('/payment_methods/new')
          .then(response => response.json())
          .then(json => __this.clientSecret = json.client_secret)
          .then(() => __this.loading = false );
      },
      mount: function (id) {
        this.fetchClientSecret();
        cardElement.mount(`#${id}`);
      },
      error: null,
      confirmCardSetup: function (successCallback) {
        this.loading = true;
        stripe.confirmCardSetup(this.clientSecret, {
          payment_method: {
            card: cardElement,
          }
        }).then(result => {
          if(!window.bidProjectUrl && !window.reload)
            this.loading = false;
          else if (window.reload)
            setTimeout(() => window.location.reload(), 3000)
          if (result.error) {
            this.error = result.error.message;
          } else {
            successCallback();
          }
        });
      },
    };
  };

  if (typeof window !== "undefined") {
    window.stripeFormApp = stripeFormApp;
  }

  // This fixes the error unable to post message to https://js.stripe.com.
  // Recipient has origin https://builderbreeze.com. Cause by turbolinks.
  const stripeIFrameQuery = 'iframe[src^="https://js.stripe.com"]';
  document.addEventListener('turbo:before-render', function (event) {
    const stripeIFrame = document.querySelector(stripeIFrameQuery);
    const newStripeIFrame = event.detail.newBody.querySelector(stripeIFrameQuery);
    if (stripeIFrame && !newStripeIFrame){
      event.detail.newBody.appendChild(stripeIFrame)
    }
  });


  return stripeFormApp;
})();
