(function () {

  const advancedSearchApp = () =>  {
    return {
      open: false,
      checked: true,
      select: function() {
        let container = document.getElementById('checkboxes-trades-container-js');
        let checkboxes = container.querySelectorAll('input[type="checkbox"]');
        for(let i=0; i<checkboxes.length; i++) {
          if (this.checked) {
            checkboxes[i].checked = false;
            checkboxes[i].value = 0;
          } else {
            checkboxes[i].checked = true;
            checkboxes[i].value = 1;
          }
        }
        this.checked = !this.checked;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.advancedSearchApp = advancedSearchApp;
  }
  return advancedSearchApp;
})();