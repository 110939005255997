(function () {
  const fileUpload = () => {
    return {
      isDropping: false,
      isUploading: false,
      progress: 0,
      handleFileSelect: function (event) {
        if (event.target.files.length) {
          this.uploadFiles(event.target.files)
        }
      },
      handleFileDrop: function (event) { 
        if (event.dataTransfer.files.length > 0) {
          var fileInput = document.getElementById('attachment_file');
          if(!fileInput){
            var fileInput = document.getElementById('bid_documents');
          }
          fileInput.files = event.dataTransfer.files;
          document.getElementById("attachment_form").submit();
          document.getElementById("attchment_loader_block").__x.$data.loader = true;
        }
      },
    };
  }
if (typeof window !== "undefined") {
  window.fileUpload = fileUpload;
}

return fileUpload;
})();