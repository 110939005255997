(function () {
  const attachmentListApp = function (attachments, tags) {
    return {
      attachments,
      tags,
      activeAttachments: function () {
        const activeTags = this.tags.filter(t => t.active).map(t => t.label);

        if (activeTags.length == 0) {
          return this.attachments
        }

        return this.attachments
                   .filter(attachment => attachment.tags
                                                   .filter(t => activeTags.indexOf(t) > -1)
                                                   .length);
      },
    }
  };

  if (typeof window !== "undefined") {
    window.attachmentListApp = attachmentListApp;
  }

  return attachmentListApp;
})();
