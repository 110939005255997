(function () {
  const attachmentButtonApp = () => {
    return {
      file: 'Browse File',
      validateFile: function (attachment, attachment_type){
        var file_size = attachment_type == 'image' ? 50 : (attachment_type == 'video' ? 300 : 100)
        var file_type = attachment_type == 'image' ? ['image/jpg','image/gif','image/png','image/jpeg','image/bmp','image/tiff','image/svg+xml'] : (attachment_type == 'video' ? ['video/mp4','video/ogg','video/mpeg4','video/webm'] : ['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/pdf','text/plain'])
        var msg_formats = attachment_type == 'image' ? ['jpg','gif','png'] : (attachment_type == 'video' ? ['mp4','ogg','mpeg4','webm'] : ['txt','doc','docx','pdf','xls','xlsx'])
        if((attachment.size / (1024 * 1024)) > file_size){
          this.file = 'Browse '+attachment_type;
          document.getElementById('attachment_file').value = ''
          document.getElementById("attachment_block").__x.$data.errortext = attachment_type+" size cannot be greater than "+ file_size +"MB";
          this.file = 'Browse '+attachment_type
        }
        if(attachment_type == 'video' && !attachment.type.includes('video')){
          document.getElementById('attachment_file').value = ''
          this.file = 'Browse '+attachment_type
          document.getElementById("attachment_block").__x.$data.errortext = "Invalid video format";
        }else if(attachment_type != 'video' && !file_type.includes(attachment.type)){
          document.getElementById('attachment_file').value = ''
          this.file = 'Browse '+attachment_type
          document.getElementById("attachment_block").__x.$data.errortext = attachment_type+" format should be in "+  msg_formats.toString();
        }
      },
      autoValidateFile: function (){
        var attachments = document.getElementById('attachment_file').files
        for (let i = 0; i < attachments.length; i++) {
          var attachment = attachments[i]
          var image_supported_types = ['image/jpg','image/gif','image/png','image/jpeg','image/bmp','image/tiff','image/svg+xml'];
          var video_supported_types = ['video/mp4','video/ogg','video/mpeg4','video/webm'];
          var file_supported_types = ['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/pdf','text/plain'];
          attachment_type = '';
          if (image_supported_types.includes(attachment.type)){
            attachment_type = 'image';
          }else if(video_supported_types.includes(attachment.type)){
            attachment_type = 'video';
          }else if(file_supported_types.includes(attachment.type)){
            attachment_type = 'file';
          }else{
            document.getElementById('attachment_file').value = ''
            document.getElementById("attachment_block").__x.$data.errortext = attachment.name+" "+attachment.name.split('.').pop()+" Invalid file format";
            return;
          }
          var file_size = attachment_type == 'image' ? 50 : (attachment_type == 'video' ? 300 : 100)
          var msg_formats = attachment_type == 'image' ? ['jpg','gif','png','bmp','tiff','svg'] : (attachment_type == 'video' ? ['mp4','ogg','mpeg4','webm'] : ['txt','doc','docx','pdf','xls','xlsx'])
          if((attachment.size / (1024 * 1024)) > file_size){
            document.getElementById('attachment_file').value = ''
            document.getElementById("attachment_block").__x.$data.errortext = attachment_type+" size cannot be greater than "+ file_size +"MB";
            return;
          }
        }
        // document.getElementById("attachment_attachment_type").value = attachment_type
        document.getElementById("attachment_form").submit();
        document.getElementById("attchment_loader_block").__x.$data.loader = true;
      },
      getNameFile: function (attachment_type) {
        if (document.getElementById('attachment_file').value != '') {
          var attached_files = document.getElementById('attachment_file').files;
          if (attached_files.length > 1) {
            this.file = attached_files.length + ' Files selected'
            for(var i=0;i < attached_files.length;i++){
              this.validateFile(attached_files[i], attachment_type);
            }
          } else {
            this.file = attached_files[0].name
            this.validateFile(attached_files[0], attachment_type);
          }
        } else {
          this.file = 'Browse '+attachment_type;
        }
      },
      validateAttachmentForm: function () {
        if(((document.getElementById('attachment_file') && document.getElementById('attachment_file').value != '') || (document.getElementById('attachment_text_link') && document.getElementById('attachment_text_link').value != '')) && document.getElementById('attachment_name').value != '' && document.getElementById('attachment_attachment_type').value != '') {
          document.getElementById("loader_block").__x.$data.loader = true;
        }
      }
    }
  };

  if (typeof window !== "undefined") {
    window.attachmentButtonApp = attachmentButtonApp;
  }

  return attachmentButtonApp;
})();
