(function () {
  const modalApp = function (modalDialogId, confirmModalId) {
    return {
      opened: false,
      confirmationUri: null,
      confirmationMethod: null,
      text_1: '',
      data: null,
      loading: false,
      openConfirmationModal: function (event) {
        const modalDialog = document.getElementById(modalDialogId);

        this.confirmationUri = event.detail.uri;
        this.confirmationMethod = event.detail.method || 'delete';
        this.loading = event.detail.loading || false;
        this.text_1 = event.detail.text || 'The action you are about to take will be permanent.';
        this.data = event.detail.data || { title: 'Are you sure?',
                                           text_1: this.text_1,
                                           text_2: 'Are you sure you want to continue?',
                                           text_button: 'Yes, do it',
                                           class_color_button: 'bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red'  };
        modalDialog.innerHTML = document.getElementById(confirmModalId).innerHTML;
        this.opened = true;
      },
      openModal: function (event) {
        const modalDialog = document.getElementById(modalDialogId);

        if (event.detail.template) {
          modalDialog.innerHTML = document.getElementById(event.detail.template).innerHTML;
        }

        if (event.detail.callback) event.detail.callback();

        this.opened = true;
        this.data = event.detail.data;
      },
      dismiss: function () {
        this.opened = false;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.modalApp = modalApp;
  }

  return modalApp;
})();
