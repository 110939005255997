(function () {
  const reviewStarsApp = () => {
    return {
      rate: 5,
      updateRate: function () {
        this.rate = document.getElementById('range').value;
      },
      dynamicUpdateRate: function (i) {
        this.rate = document.getElementById('range'+i).value;
        document.getElementById('rate'+i).value = document.getElementById('range'+i).value;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.reviewStarsApp = reviewStarsApp;
  }

  return reviewStarsApp;
})();
