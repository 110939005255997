(function () {
  const filterPlace = place => type => {
    const component = place.address_components.filter(comp => comp.types[0] === type);

    return (component && component[0] && component[0].short_name) || undefined;
  };

  const placeInputApp = function (placeInputId, address) {
    let $dispatch;
    const placeInput = document.getElementById(placeInputId);

    const autocomplete = new google.maps.places.Autocomplete(placeInput, {
      type: ['geocode'],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      const filterComponent = filterPlace(place);

      const streetNumber = filterComponent('street_number');
      const route = filterComponent('route');
      const address = streetNumber && route ? `${streetNumber} ${route}` : null;
      const city = filterComponent('locality');
      const county = filterComponent('administrative_area_level_2');
      const state = filterComponent('administrative_area_level_1');
      const zip = filterComponent('postal_code');

      $dispatch('address-changed', { address, city, county, state, zip, latitude, longitude  });
    });

    return {
      address,
      setDispatch: function (dispatch) {
        $dispatch = dispatch;
      },
      updateAddress: function (address) {
        this.address = address;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.placeInputApp = placeInputApp;
  }

  return placeInputApp;
})();
