(function () {
  const paymentScheduleExecuteFormApp = function (paymentSchedules) {
    function findPaymentSchedule(id) {
      for (var i=0; i < paymentSchedules.length; i++) {
          if (paymentSchedules[i].id === id) {
              return paymentSchedules[i];
          }
      }
    }

    return {
      initPaymentSchedule: function(paymentScheduleId) {
        console.log(paymentScheduleId);
        let __this = this;
        __this.paymentSchedule = paymentScheduleId;
      },
      openForm: false,
      uri: document.getElementsByClassName("url-approve-js")[0].textContent,
      paymentSchedule: {},
      stripe_id: ''
    };
  };

  if (typeof window !== "undefined") {
    window.paymentScheduleExecuteFormApp = paymentScheduleExecuteFormApp;
  }
  return paymentScheduleExecuteFormApp;
})();
